import { useContext } from 'react';
import BlockScroller from "../BlockScroller";
import BlockHead from "../_BlockHead";
import {StoreContext} from "../../../../stores/StoreLoader";
import {observer} from "mobx-react";
import {IBlockComponentProps} from "../_Block";
import moment from "moment-timezone";
import {isTrueMultiDayEvent} from "../../../../utils/SchoolBlocksUtilities";

interface IEventBlockProps extends IBlockComponentProps {
    blockObj: IEventBlockObj
}

const EventListBlock = observer((props: IEventBlockProps) => {
    const {title, blockType, fullCalendarUrl, events} = props.blockObj;
    const {modalStore} = useContext(StoreContext);

    return (
        <BlockScroller blockRef={props.blockRef}
                       blockObj={props.blockObj}
                       htmlId={props.htmlId}
                       setShowExpandButton={props.setShowExpandButton}
                       outsideGrid={props.outsideGrid}>
            <BlockHead blockType={blockType} editableTitle={props.blockObj?.blockModel?.title || "Events"} blockId={props.blockObj.id}>
                <a style={{"position": "static"}} title="Click to view the full calendar"
                   aria-label="Click to view the full calendar"
                   href={fullCalendarUrl}>
                    <div style={{"display": "inline-block", "fontSize": "1.4em", "marginRight": "0.3em"}}
                         className="fas fa-calendar" role="img"
                         aria-hidden="true"/>
                </a>
            </BlockHead>
            <div className="sb-blockContent sb-event-list-block">
                {events && events.length > 0 ? events.map(item => {
                    function handleClick() {
                        modalStore.addModal({
                            type: 'schoolBlocksEvent',
                            event: item,
                        });
                    }

                    const startTime = moment(item.start);
                    const endTime = moment(item.end);
                    const end_hour = endTime.hour()
                    const end_minutes = endTime.minutes()
                    const end_seconds = endTime.seconds()
                    const end_milliseconds = endTime.milliseconds()
                    if (end_hour === 0 && end_minutes === 0 && end_seconds === 0 && end_milliseconds === 0){
                        endTime.subtract(1,"millisecond")
                    }

                    const formattedStartDay = startTime.format("D");
                    const formattedStartMonth = startTime.format("MMM");
                    const formattedEndDay = endTime.format("D");

                    return <button onClick={handleClick}
                                   data-id={item.data.id}
                                   key={item.data.id}>
                        <section className="clearfix" style={{"marginBottom": "1em"}}>
                            {isTrueMultiDayEvent(item, startTime, endTime) ?
                                <div className="sb-event-list-date-block sb-organization-color-element-bg sb-multiday"
                                     aria-label={`${formattedStartDay} to ${formattedEndDay}`}>
                                    <div className="sb-event-list-date-month"
                                         aria-hidden="true">{formattedStartMonth}
                                    </div>
                                    <div className="sb-event-list-date-day sb-multiday" aria-hidden="true">
                                        {`${formattedStartDay}-${formattedEndDay}`}
                                    </div>
                                </div> :
                                <div className="sb-event-list-date-block sb-organization-color-element-bg"
                                     aria-label={`${formattedStartMonth} ${formattedStartDay}`}>
                                    <div className="sb-event-list-date-month"
                                         aria-hidden="true">{formattedStartMonth}
                                    </div>
                                    <div className="sb-event-list-date-day"
                                         aria-hidden="true">{formattedStartDay}
                                    </div>
                                </div>}
                            <div className="sb-event-list-text-block">
                                <div className="h4">{item.title}</div>
                                {item.data.location && <small>{item.data.location}</small>}
                            </div>
                        </section>
                    </button>
                }) : <div>No events found!</div>}
            </div>
        </BlockScroller>
    )
})

export default EventListBlock;